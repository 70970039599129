<script setup lang="ts">
import { QIcon, QPopupProxy } from 'quasar';
import { ref } from 'vue';
import { WsInput, WsDatePicker } from './index';

defineOptions({
  inheritAttrs: false,
});

const model = defineModel<string>();

const props = withDefaults(
  defineProps<{
    autofocus?: boolean;
    clearable?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    error?: string;
    id?: string;
    label?: string;
    placeholder?: string;
    size: 'sm' | 'md' | 'lg';
    aid: string;
    type?: 'datetime' | 'date' | 'time';
  }>(),
  {
    size: 'md',
    type: 'datetime',
  }
);

const emit = defineEmits<{
  focus: [event: FocusEvent];
  blur: [event: FocusEvent];
  clear: [];
}>();

const wsInput = ref<typeof WsInput | null>(null);

defineExpose({
  focus: () => wsInput.value?.focus(),
});

const validateMask = () => {
  const patterns = {
    datetime:
      /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(\d{2}|\d{4}) (0[1-9]|1[0-2]):([0-5][0-9]) (AM|PM)$/,
    date: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(\d{2}|\d{4})$/,
    time: /^(0[1-9]|1[0-2]):([0-5][0-9]) (AM|PM)$/,
  };
  const formats = {
    datetime: 'MM/DD/YY hh:mm (AM/PM)',
    date: 'MM/DD/YY',
    time: 'hh:mm (AM/PM)',
  };

  if (!patterns[props.type]) {
    return "Invalid type. Valid types are 'datetime', 'date', and 'time'.";
  }

  if (!patterns[props.type].test(model.value || '')) {
    return `${formats[props.type]}`;
  }

  return true;
};
</script>

<template>
  <WsInput
    :id="id"
    ref="wsInput"
    v-model="model"
    :autofocus="autofocus"
    :clearable="clearable"
    :disabled="disabled"
    :readonly="readonly"
    :error="error"
    :label="label"
    :placeholder="placeholder"
    :rules="[validateMask]"
    :size="size"
    :aid="aid"
    @focus="emit('focus', $event as FocusEvent)"
    @blur="emit('blur', $event as FocusEvent)"
    @clear="emit('clear')"
  >
    <template #append>
      <QIcon
        name="fa-light fa-calendar-days"
        color="gray-500"
        size="16px"
        class="cursor-pointer"
      >
        <QPopupProxy
          cover
          transition-show="scale"
          transition-hide="scale"
          class="ws-date-picker-proxy"
          style="box-shadow: none; background: transparent"
        >
          <WsDatePicker
            v-model="model"
            :aid="`${aid}-input-date-picker`"
            :type="type"
            @input="validateMask"
          />
        </QPopupProxy>
      </QIcon>
    </template>
  </WsInput>
</template>
